@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tabs {
  display: flex;
  border-bottom: 2px solid #e5e7eb;
}

.tab {
  padding: 0.5rem 1rem;
  cursor: pointer;
  border: none;
  background-color: transparent;
  outline: none;
  font-weight: 600;
  color: #4b5563;
  border-bottom: 2px solid transparent;
  transition: border-color 0.2s ease;
}

.tab:hover {
  color: #1f2937;
}

.tab.active {
  border-bottom-color: #3b82f6;
  color: #1d4ed8;
}

.tab-content {
  padding-top: 1rem;
}

.settings-container {
  min-height: 400px; /* Adjust the height as needed */
}

.custom-scrollbar {
  /* Custom Scrollbar Styling */
  scrollbar-width: thin;  /* Firefox */
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

/* Ensure scrollbars are visible when needed */
.custom-scrollbar {
  overflow-y: auto;
}

